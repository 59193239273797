export default defineNuxtRouteMiddleware(async (to) => {
  const { setLevel } = useUserPanelStore();
  const { defaultLevel, availableLevels } = await useAuth();
  const levelNumber = Number(to.query.level);
  if (!isNaN(levelNumber) && availableLevels.value?.includes(levelNumber)) {
    setLevel(levelNumber);
  } else {
    if (defaultLevel.value !== undefined) {
      setLevel(defaultLevel.value);
      return navigateTo({
        ...to,
        query: { ...to.query, level: defaultLevel.value },
      });
    }
  }
});
